import React, { useEffect, useState } from "react";
import { addNotification } from "../Redux/Slices/NotificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { addDelayed } from "../Redux/Slices/DelayedSlice";

const CountUpTimer = ({ estimatedTime, create_at, setState, order_id }) => {
  const [timeLeft, setTimeLeft] = useState("00:00:00");
  const { delayed } = useSelector((state) => state.delayed);

  const dispatch = useDispatch();

  // set the timer to its previous state
  useEffect(() => {
    const create = new Date(create_at);
    const now = new Date();

    const createHour = create.getHours();
    const createMinut = create.getMinutes();
    const createSecond = create.getSeconds();

    const hourNow = now.getHours();
    const minutNow = now.getMinutes();
    const secondsNow = now.getSeconds();

    const diffHours = hourNow - createHour;
    const diffMinuts = createMinut - minutNow;
    const diffSecond = createSecond - secondsNow;

    // Estimated Time
    const estimatedArr = estimatedTime.split(":");
    const estimated = new Date();
    estimated.setHours(estimatedArr[0], estimatedArr[1], estimatedArr[2]);

    let estimatedHour = +estimatedArr[0];
    let estimatedMinuts = +estimatedArr[1];
    let estimatedSecond = +estimatedArr[2];

    const estimatedTimeInSeconds =
      +estimatedHour * 3600 + estimatedMinuts * 60 + estimatedSecond;

    const differentInSeconds =
      Math.abs(diffHours) * 3600 +
      Math.abs(diffMinuts) * 60 +
      Math.abs(diffSecond);

    //console.log(differentInSeconds);

    if (estimatedTimeInSeconds < differentInSeconds) {
      setTimeLeft(convertSecondsToTime(differentInSeconds));
      setState(3);

      if (!delayed.includes(order_id)) dispatch(addDelayed(order_id));
    } else {
      const startFrom = convertSecondsToTime(differentInSeconds);

      setTimeLeft(startFrom);
    }
  }, []);

  useEffect(() => { 
    const interval = setInterval(() => {
      const [hours, minutes, seconds] = timeLeft.split(":");
      let secondsLeft =
        Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
      if (secondsLeft > 0) {
        const estimatedArr = estimatedTime.split(":");
        const estimated = new Date();

        estimated.setHours(estimatedArr[0], estimatedArr[1], estimatedArr[2]);

        let estimatedHour = +estimatedArr[0];
        let estimatedMinuts = +estimatedArr[1];
        let estimatedSecond = +estimatedArr[2];

        const estimatedTimeInSeconds =
          +estimatedHour * 3600 + estimatedMinuts * 60 + estimatedSecond;

        if (secondsLeft === estimatedTimeInSeconds) {
          setState(3);
          dispatch(addNotification({ order_id, type: "delay" }));
        }
        secondsLeft++;
        const formattedTimeLeft = [
          Math.floor(secondsLeft / 3600),
          Math.floor((secondsLeft % 3600) / 60),
          secondsLeft % 60,
        ]
          .map((v) => String(v).padStart(2, "0"))
          .join(":");

        setTimeLeft(formattedTimeLeft);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);
  return (
    <span
      style={{
        textDecoration: "underline",
        color: timeLeft == "00:00:00" ? "rgb(43, 191, 102)" : "",
      }}
    >
      {timeLeft}
    </span>
  );
};

export default CountUpTimer;

function convertSecondsToTime(given_seconds) {
  const hours = Math.floor(given_seconds / 3600);
  const minutes = Math.floor((given_seconds - hours * 3600) / 60);
  const seconds = given_seconds - hours * 3600 - minutes * 60;

  const hoursString = hours.toString().padStart(2, "0");
  const minutesString = minutes.toString().padStart(2, "0");
  const secondsString = seconds.toString().padStart(2, "0");

  return `${hoursString}:${minutesString}:${secondsString}`;
}
