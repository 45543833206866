import React, { useState } from "react";
import { Box, Button, Paper, Stack } from "@mui/material";
import SubOrder from "./SubOrder";
import { request } from "../api/request";
import Timer from "./Timer";
import { green } from "@mui/material/colors";
import { useSelector } from "react-redux";
import CountUpTimer from "./CountUpTimer";
import "../App.css";

const Order = ({ order, type, refetch, setLoading }) => {
  const [orderState, setOrderState] = useState(order.status);
  let status = {};

  if (order.takeaway) {
    status.color = "red";
    status.state = "Takeaway";
  } else if (orderState === 2) {
    status.color = "#6964dc";
    status.state = "preparing";
  } else if (order.is_update) {
    status.color = "#15c04e";
    status.state = "Changed";
  } else if (orderState === 1) {
    status.color = "#639dd5";
    status.state = "new";
  } else {
    status.color = "#e11d48";
    status.state = "delay";
  }
  //console.log(order);
  const makeOrderDone = () => {
    setLoading(true);
    request({
      url: `/order/ChangeToDone/${order.order_id}`,
      method: "POST",
    })
      .then((resp) => {
        refetch[0].refetch();
        refetch[1].refetch();
        setLoading(false);
        window.location.reload();
      })
      .catch();
  };
  const makeOrderStartPreparing = () => {
    setLoading(true);

    request({
      url: `/order/ChangeToPrepare/${order.order_id}`,
      method: "POST",
    })
      .then((resp) => {
        refetch[0].refetch();
        refetch[1].refetch();
        window.location.reload();
        setLoading(false);
      })
      .catch();
  };

  const deleteOrder = () => {
    request({
      url: `/kitchen/order/${order.order_id}`,
      method: "DELETE",
    })
      .then((resp) => {
        //console.log(resp);
        // setInfo((prev) => {
        //   return { ...prev, isLoading: false };
        // });
        window.location.reload();
      })
      .catch();
  };

  const { delayed } = useSelector((state) => state.delayed);
  //console.log(delayed);
  return (
    <Box sx={{ paddingRight: "1rem", paddingLeft: "1rem", paddingTop: "20pz" }}>
      <Box
        sx={{
          background: status.color,
          padding: "0.5rem 4rem",
          color: "#fff",
          fontWeight: "bold",
          borderTopLeftRadius: "0.5rem",
          borderTopRightRadius: "0.5rem",
          textAlign: "center",
          fontSize: "20px",
          paddingBottom: "20px",
          paddingY: "15px",
        }}
      >
        {status.state}
      </Box>
      <Paper
        sx={{
          padding: "1rem",
          borderRadius: "10px",
          marginTop: "-4px",
          border: `${order.isNew ? "3px solid" : "none"}`,
          overflowY: "auto",
          borderColor: green["200"],
          height: "65vh",
          boxShadow: order.isNew
            ? `0 2px 18px 3px ${green["300"]}`
            : green["200"],
        }}
        className="card"
      >
        <Stack
          p={1}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
          gap={3}
        >
          <Box sx={{ fontWeight: "bold" }}>
            {" "}
            {order.table_id == "1111"
              ? "Out Door"
              : "Table : " + order.table_id}
          </Box>
          <Box sx={{ fontWeight: "bold" }}>Order No : {order.order_id}</Box>
          {type === "onGoing" && (
            <Box sx={{ fontWeight: "bold" }}>
              Timer :{" "}
              <CountUpTimer
                setState={setOrderState}
                order_id={order.order_id}
                estimatedTime={order.timer}
                create_at={order.time_start}
              />
            </Box>
          )}
        </Stack>
        <Stack
          flexWrap={"wrap"}
          p={1}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
        >
          {/* <Box
            sx={{
              background: status.color,
              padding: "0.5rem 4rem",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: "0.5rem",
            }}
          >
            {status.state}
          </Box> */}
          <Box sx={{ fontWeight: "bold" }}>
            Order time :{" "}
            <span style={{ color: "#000" }}>
              {new Date(order.order_time).toLocaleTimeString()}
            </span>
          </Box>
        </Stack>
        {order.subOrders.map((item, index) => {
          return <SubOrder color={status.color} key={index} subOrder={item} />;
        })}
      </Paper>
      <Stack gap={2} direction={"row"}>
        <Button
          style={{
            color: "#fff",
            fontWeight: "bold",
            backgroundColor: status.color,
            width: "100%",
            outline: "none",
            border: "none",
            padding: "0.7rem ",
            margin: "1rem 0  0",
            borderRadius: "5px",
            transition: "0.2s",
          }}
          onClick={
            type === "new" && "preparing" && "delay"
              ? makeOrderStartPreparing
              : makeOrderDone
          }
        >
          {type === "new" && "preparing" && "delay"
            ? "start preparing"
            : "done"}
        </Button>

        <Button
          variant="error"
          style={{
            color: "#fff",
            fontWeight: "bold",
            backgroundColor: "rgb(255, 77, 100)",
            width: "100%",
            outline: "none",
            border: "none",
            padding: "0.7rem ",
            margin: "1rem 0  0",
            borderRadius: "5px",
            transition: "0.2s",
          }}
          onClick={deleteOrder}
        >
          Delete
        </Button>
      </Stack>
    </Box>
  );
};

export default Order;
