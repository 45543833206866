import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  delayed: [],
};
const DelayedSlice = createSlice({
  name: "delayed",
  initialState,
  reducers: {
    addDelayed: (state, action) => {
      state.delayed.push(action.payload);
    },
  },
});

export const { addDelayed } = DelayedSlice.actions;

export default DelayedSlice.reducer;
