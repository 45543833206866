import React from "react";
import { Box } from "@mui/material";
import logo from "../images/logo.png";
const Logo = () => {
  return (
    <Box
      sx={{
        fontSize: "2.5rem",
        textAlign: "center",
        padding: "2rem 0 0",
        color: "#333",
        fontWeight: "bold",
      }}
    >
      <img src={logo} alt="ml" style={{ width: "160px" }} />
    </Box>
  );
};

export default Logo;
