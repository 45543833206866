import { Box, Grid, Stack, Button } from "@mui/material";
import Pusher from "pusher-js";
import Order from "../components/Order";
import Heading from "../components/Heading";
import { useEffect, useState } from "react";
import Notifications from "../components/Notifications";
import Timer from "../components/Timer";
import { request } from "../api/request";
import Loader from "../components/loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../Redux/Slices/NotificationSlice";
import { useErrorBoundary } from "react-error-boundary";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate } from "react-router-dom";
import { useQueries } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import Logo from "../components/Logo";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Pagination } from "swiper/modules";

const Orders = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [newOrder, setNewOrder] = useState([]);
  const logoutHandler = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const { noti } = useSelector((state) => state.notification);
  const { delayed } = useSelector((state) => state.delayed);

  const dispatch = useDispatch();

  const results = useQueries({
    queries: [
      {
        queryKey: ["orders"],
        queryFn: () => {
          return request({
            url: `/orders/kitchen/${localStorage.getItem("restaurant_id")}`,
          });
        },
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ["getToDone"],
        queryFn: () => {
          return request({
            url: `/getToDone/${localStorage.getItem("restaurant_id")}`,
          });
        },
        refetchOnWindowFocus: false,
      },
    ],
  });
  console.log("results", results);
  useEffect(() => {
    if (noti.length == 0) {
      setNewOrder([]);
      results[0].refetch();
    }
  }, [noti]);

  // New Orders realtime
  useEffect(() => {
    const pusher = new Pusher("1604fd4ad916b0f5ed4d", {
      cluster: "mt1",
    });

    const channel = pusher.subscribe(
      `order.${localStorage.getItem("restaurant_id")}`
    );

    console.log(channel);

    channel.bind("newOrder", (data) => {
      console.log(data, "this is real time");
      dispatch(addNotification({ order_id: data.order.id, type: "new" }));
      const order = data;
      order.order.isNew = true;
      results[0].refetch();
      // setNewOrder((prev) => {
      //   return [...prev, order];
      // });
    });

    return () => {
      channel.unbind();
      pusher.disconnect();
    };
  }, []);
  console.log(results[0].data);

  if (results[0].isError || results[1].isError) {
    return <Box>Error</Box>;
  }
  return (
    <Box
      sx={{
        minHeight: "100vh",

        backgroundColor: "rgb(241 245 249)",
      }}
    >
      <Notifications />

      <div
        style={{
          padding: "0.5rem 0rem",
          boxShadow: "2px 0 21px 1px #d4d4d8",
        }}
      >
        <Logo />
        <Button
          onClick={logoutHandler}
          variant="contained"
          sx={{
            position: "absolute",
            top: "35px",
            right: "2rem",
            background: "rgb(241 245 249)",
            border: 0,
            color: "#6664de",

            fontSize: "0.8rem",
          }}
        >
          <LogoutIcon
            style={{
              fontSize: "1.8rem",
              paddingLeft: "0.5rem",
              position: "relative",
            }}
          />
        </Button>
      </div>

      {results[0].isLoading ||
      results[1].isLoading ||
      results[0].isRefetching ||
      loading ||
      results[1].isRefetching ? (
        <Loader />
      ) : (
        <div
          justifyContent={"center"}
          container
          style={{ padding: "4rem 0rem", width: "" }}
        >
          <Swiper
            slidesPerView={3.5}
            spaceBetween={30}
            freeMode={true}
            enabled={true}
            onlyInViewport={true}
            modules={[FreeMode, Pagination]}
            className="mySwiper"
          >
            {results[1].data.data.data.map((order1) => {
              console.log(order1, " order");
              const order = {
                table_id: order1.table.table_num,
                isNew: order1.isNew,
                order_id: order1.id,
                timer: order1.estimatedForOrder,
                status: order1.status,
                order_time: order1.created_at,
                time_start: order1.time_start,
                is_update: order1.is_update,
                takeaway: order1.takeaway,
                subOrders: order1.products.map((item) => {
                  return {
                    name: item.name,
                    amount: item.qty,
                    note: item.note,
                    ingre: item.extra.map((ingredient) => {
                      return { name: ingredient.name };
                    }),
                    removed: item.removeIngredient.map((ingredient) => {
                      return { name: ingredient.name };
                    }),
                  };
                }),
              };
              return (
                <SwiperSlide>
                  <Grid
                    sx={{
                      animation: delayed.includes(order.order_id)
                        ? `bump 0.6s infinite`
                        : "",
                    }}
                    item
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <Order
                      refetch={results}
                      type={"onGoing"}
                      order={order}
                      setLoading={setLoading}
                    />
                  </Grid>
                </SwiperSlide>
              );
            })}

            {results[0].data.data.data.map((order1) => {
              //console.log(order1, " order");
              const order = {
                table_id: order1.table.table_num,
                isNew: order1.isNew,
                order_id: order1.id,
                timer: order1.estimatedForOrder,
                status: order1.status,
                order_time: order1.created_at,
                time_start: order1.time_start,
                is_update: order1.is_update,
                takeaway: order1.takeaway,
                subOrders: order1.products.map((item) => {
                  return {
                    name: item.name,
                    amount: item.qty,
                    note: item.note,
                    ingre: item.extra.map((ingredient) => {
                      return { name: ingredient.name };
                    }),
                    removed: item.removeIngredient.map((ingredient) => {
                      return { name: ingredient.name };
                    }),
                  };
                }),
              };
              return (
                <SwiperSlide>
                  <Grid
                    sx={{
                      animation: delayed.includes(order.order_id)
                        ? `bump 0.6s infinite`
                        : "",
                    }}
                    item
                    md={4}
                    sm={6}
                    xs={12}
                  >
                    <Order
                      refetch={results}
                      type={"new"}
                      order={order}
                      setLoading={setLoading}
                    />
                  </Grid>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
      {newOrder?.length !== 0 && (
        <SwiperSlide
          justifyContent={"start"}
          container
          style={{ padding: "3rem 0" }}
        >
          {newOrder.map((order1) => {
            //console.log(order1, "datra");
            const order = {
              table_id: order1.order.table.table_num,
              isNew: order1.order.isNew,
              order_id: order1.order.id,
              timer: order1.order.estimatedForOrder,
              status: order1.order.status,
              order_time: order1.order.created_at,
              time_start: order1.order.time_start,
              subOrders: order1.order.products.map((item) => {
                return {
                  name: item.name,
                  amount: item.qty,
                  note: item.note,
                  ingre: item.extra.map((ingredient) => {
                    return { name: ingredient.name };
                  }),
                  removed: item.removeIngredient.map((ingredient) => {
                    return { name: ingredient.name };
                  }),
                };
              }),
            };
            return (
              <Order
                refetch={results}
                type={"new"}
                order={order}
                setLoading={setLoading}
              />
            );
          })}
        </SwiperSlide>
      )}
    </Box>
  );
};

export default Orders;
