import React from "react";
import { Typography } from "@mui/material";
const Heading = (props) => {
  return (
    <>
      <Typography
        sx={{
          fontSize: props.size,
          fontWeight: "600",
          width: "fit-content",

          position: "relative",
          padding: "1rem 0.5rem",
          marginTop:"-15px",
          "&:before": {
            content: "''",
            position: "absolute",
            width: props.width,
            background: "linear-gradient(to right, #d22afc, #a758ed,#7073de)",

            height: "6px",
            top: "50%",
            transform: "translate(-100%,-50%)",
            left: "0",

            borderRadius: "10px",
          },
          "&:after": {
            content: "''",
            position: "absolute",
            width: props.width,
            background: "linear-gradient(to right, #d22afc, #a758ed,#7073de)",
            height: "6px",
            top: "50%",
            transform: "translate(100%,-50%)",
            right: "0",
            borderRadius: "10px",
          },
        }}
        m={"0  auto 1rem"}
        textAlign={"center"}
      >
        {props.children}
      </Typography>
    </>
  );
};

export default Heading;
